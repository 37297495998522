import React from 'react'
import { useNavigate } from 'react-router-dom'
import './TemplateMemberListBureauCard.css'


const TemplateMemberListBureauCard = (props) => {
    const {name, role, picture, type} = props;
    const navigate = useNavigate()
  return (
    <div className='member-bureau-list__card d-flex flex-wrap' style={{ width: type !== "conseiller" ? "100%" : "100%", maxWidth: role === "Président" && "650px", height: role === "Président" && "420px" }}>
        <div className="member-bureau-list__card__image" style={{ backgroundImage: `url(${picture})`, width: role === "Président" && "350px", height: role === "Président" && "320px"}}>
        </div>
        <div className="member-bureau-list__card__text-content">
            <div className="description">{name}</div>
            <div className="footer-content d-flex">
                <div className="footer-content__button text-center">
                    <button className="download-button">{role}</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TemplateMemberListBureauCard