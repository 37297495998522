import React from 'react'
import './TemplateDocumentationListCard.css'
import image from '.././../assets/images/img7.jpg'
import { useNavigate, useParams } from 'react-router-dom'


const TemplateDocumentationListCard = (props) => {
    
    const {id, title, content, created_at, document_url, image_url, sub_title, pageNumber} = props
    const navigate = useNavigate()
  const {menu} = useParams()
  return (
    <div className='documentation-list__card d-flex flex-wrap'>
        <div className="documentation-list__card__image" style={{ backgroundImage: `url(${image_url ? image_url : image})` }}>
        </div>
        <div className="documentation-list__card__text-content">
            <div className="title">{title.substring(
                            0,
                            70
                        )}...</div>
             <div className="description ql-editor" dangerouslySetInnerHTML={{ __html: (content.substring(
                            0,
                            50
                        ) + "<span>...</span>") }}></div> 

            <hr />
                <div className="footer-content__time">{created_at.slice(8,10)} / {created_at.slice(5,7)} / {created_at.slice(0,4)}<span className='green-period'>.</span></div>
            <div className="footer-content d-flex">
                <div className="footer-content__buttons">
                    <button className="read-button" onClick={() => navigate(`/documentations/${menu}/${id}/${pageNumber}`)}>Lire</button>
                    {document_url.length != 0 && <a href={document_url && document_url[0]} download>
                    <button className="download-button">Télécharger</button>
                     </a>}
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default TemplateDocumentationListCard