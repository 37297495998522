/* eslint-disable react-hooks/exhaustive-deps */
import CardOnCaroussel from "../../components/CardOnCaroussel/CardOnCaroussel";
import { getATypeOfArticles } from '../../http/http';
import React, { useState, useEffect } from 'react';
import { Carousel, Button } from "react-bootstrap";
import './ActualiteCarousel.css';
import Loader from "../Loader/Loader";


const ActualiteCarousel = () => {

  const [index, setIndex] = useState(0);
  const [actualites, setActualites] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  
  useEffect(() => {
    const loadActualitesData = async () => {
      const resp = await getATypeOfArticles("actualites");
      if (resp.response && resp.response.status !== 200) {
      } else {
        setActualites(resp.data?.data);
        setIsLoading(false)
      }
    }
    loadActualitesData();
  }, [])

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div id={'actualite-carousel'} className={'actualite-carousel'}>
      { !isLoading ?
        <>
      <Carousel activeIndex={index} onSelect={handleSelect}>

        {actualites && actualites.map((actualite, index) =>
          <Carousel.Item key={index + "w"}>
            <div className='carousel-bg' style={{ backgroundImage: `url(${actualite?.converted_image["1296_864"]})` }}></div>
            <div className="carousel-caption d-md-block">
              <CardOnCaroussel id={actualite?.id} title={actualite?.title} subtitle={actualite?.sub_title} image={actualite?.converted_image["1296_864"]} />
            </div>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
        )}

      </Carousel>
      </>  
            :
            <Loader /> }
    </div>
  )
}
export default ActualiteCarousel