import React, { useEffect, useState } from 'react';
import { ImSad } from "react-icons/im";
import FormationProgrammee from '../../components/FormationProgrammee/FormationProgrammee';
import FormationSuivie from '../../components/FormationSuivie/FormationSuivie';
import Loader from '../../components/Loader/Loader';
import { getAllTraining, getAllTrainingDone } from '../../http/http';
import "./EspaceDPO.css";

const EspaceDPO = () => {
    const [sectionActive, setSectionActive] = useState(1)
    const [formations, setFormations] = useState([]);
    const [formationsDone, setFormationsDone] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    const espaceRtData = [
        {
            id: 1,
            label: "Formation Programmée"
        },
        {
            id: 2,
            label: "Formation Suivie et Attestation"
        },
    ]

    useEffect(() => {
        if (localStorage.getItem("user_token") === "" || localStorage.getItem("user_token") === null) {
            localStorage.setItem("redirect_url", window.location.pathname);
            window.location = "/auth";
        }
    }, [])

    useEffect(() => {
        const getTrainingsData = async () => {

            const response = await getAllTraining(localStorage.getItem("user_token"))
            if (response.status === 200) {
                setFormations(response?.data)
                setIsLoading(false)
            } else {
            }
        }
        const getTrainingsDoneData = async () => {

            const response = await getAllTrainingDone(localStorage.getItem("user_token"))
            if (response.status === 200) {
                setFormationsDone(response?.data)
                setIsLoading(false)


            } else {
            }
        }

        getTrainingsData();

        getTrainingsDoneData();

    }, [localStorage.getItem("user_token")])


    if (String(localStorage.getItem('is_dpo')) == "true") {
        return (
            <div style={{ backgroundColor: "#E2E2E2", paddingTop: "40px", paddingBottom: "40px" }}>
                <div className="espace-dpo-container">
                    <p className="espace-dpo-title">ESPACE DPO</p>
                    <div className="espace-dpo-cards-container row d-flex ">
                        {espaceRtData.map((item, index) => (
                            <div key={index} className="col-sm-6 col-xs-12">
                                <button className={item.id === sectionActive ? `espace-dpo-section-button activeSection` : `espace-dpo-section-button`} onClick={() => setSectionActive(item.id)}>
                                    {item.label}
                                </button>
                            </div>
                        ))}
                    </div>
                   {!isLoading ?
                <> <div className='espace-dpo-content custom-overflow'>
                        {sectionActive === 1 && <FormationProgrammee trainings={formations} />}
                        {sectionActive === 2 && <FormationSuivie trainingsDone={formationsDone}/>}
                    </div>
                    </> :
                                    <Loader />}
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ backgroundColor: "#E2E2E2", paddingTop: "40px", paddingBottom: "40px" }}>
                <div className="espace-dpo-container">
                    <p className="espace-dpo-title">ESPACE DPO</p>
                    <div className='espace-dpo-content'>
                        <ImSad style={{ fontSize: '100px', marginTop: '100px', marginBottom: '30px' }} />
                        <p style={{ marginBottom: '100px' }}>Désolé mais vous n'avez pas accès à cette ressource...</p>
                    </div>
                </div>
            </div>
        )
    }


};

export default EspaceDPO;
