import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./fonts/Gotham-Font/GothamBold.ttf";
import "./fonts/Gotham-Font/GothamBoldItalic.ttf";
import "./fonts/Gotham-Font/GothamBook.ttf";
import "./fonts/Gotham-Font/GothamBookItalic.ttf";
import "./fonts/Gotham-Font/GothamLight.ttf";
import "./fonts/Gotham-Font/GothamLightItalic.ttf";
import "./fonts/Gotham-Font/GothamMedium.ttf";
import "./fonts/Gotham-Font/GothamMediumItalic.ttf";
import './index.css';
import './locales/i18n';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>,
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.register();
