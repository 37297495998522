import React, { useEffect, useState } from 'react'
import './MemberItemTemplate.css'
import image from '.././../assets/images/img7.jpg'
import { useParams } from 'react-router-dom'
import { getATypeOfArticles } from '../../http/http'
import HeroSection from '../../components/HeroSection/HeroSection'
import Loader from '../../components/Loader/Loader'

const MemberItemTemplate = () => {

    const { menu } = useParams()

    const [article, setArticle] = useState([]);
    // eslint-disable-next-line
    const [perPage, setPerPage] = useState(15);
    // eslint-disable-next-line
    const [pageNumber, setPageNumber] = useState(1);
    // eslint-disable-next-line
    const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
      const loadArticlesData = async () => {
          const resp = await getATypeOfArticles(menu, pageNumber)
          if (resp.response && resp.response.status !== 200) {
          } else {
              const perPageValue = resp?.data?.meta?.per_page
              setPerPage(perPageValue)
              const total = resp?.data?.meta?.total;
              setTotalPage(Math.ceil(total / perPageValue))
              setArticle(resp.data.data[0])
        setIsLoading(false)
          }
      }
        loadArticlesData();
    }, [pageNumber, menu])


    return (
        <div className='pb-5' >
        { !isLoading ?
        <>
        <HeroSection />
        <div className='member__item-template'>
            <div className='member__item-template-img' style={{ backgroundImage: `url(${article?.converted_image["1248_782"] ? article.converted_image["1248_782"] : image})` }}>
            </div>
            <div className="name">{article?.title}</div>
            <div className="job">{article?.sub_title}</div>
            <div className="text-content ql-editor"
                dangerouslySetInnerHTML={{ __html: article?.content }}
            >
            </div>
        </div>
        </>  
            :
            <Loader /> }
        </div>
    )
}

export default MemberItemTemplate