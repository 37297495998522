import React, { useEffect, useState } from 'react'
import "./ForumsDiscussionTemplate.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from 'react-router-dom';
import { getForums } from '../../http/http';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import HeroSection from '../../components/HeroSection/HeroSection';
import ForumDiscussionRow from '../../components/ForumDiscussionRow/ForumDiscussionRow';

const ForumsDiscussionTemplate = () => {

  const { menu } = useParams()
  const [articles, setArticles] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)


  const changePage = ({ selected }) => {
    let currentPage = selected + 1;
    setPageNumber(currentPage)
  }


  useEffect(() => {
    const loadArticlesData = async () => {
      const resp = await getForums(pageNumber);
      if (resp.response && resp.response.status !== 200) {
      } else {
        const perPageValue = resp?.data?.meta?.per_page
        setPerPage(perPageValue)
        const total = resp?.data?.meta?.total;
        setTotalPage(Math.ceil(total / perPageValue))

        setArticles(resp.data.data)
        setIsLoading(false)
      }
    }
    loadArticlesData();
  }, [pageNumber, menu])


  return (<div className='pb-5' >
    {!isLoading ?
      <>
        <HeroSection />
        <Breadcrumb>
          <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Forums de discussions</Breadcrumb.Item>
        </Breadcrumb>
        <div className="">
          {articles.length !== 0 ? articles.map((item, index) => <div className='' key={index}><ForumDiscussionRow
            id={item.id}
            title={item.topic}
            comments={item.comments_count}
            date={item.created_at}
            decription={item.body}

          />
          </div>) : <h1 className='m-auto p-5'>Aucune Donnée</h1>}
        </div>
        {articles && articles?.length !== 0 && <Pagination
          changePage={changePage}
          pageCount={totalPage}
          perPage={perPage}
        />}

      </>
      :
      <Loader />}
  </div>
  )
}

export default ForumsDiscussionTemplate