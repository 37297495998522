import React, { useState, useEffect } from 'react'
import './FaqTemplate.css'
import FaqSection from '../../components/FaqSection/FaqSection'
import all_faq_folders from '../../assets/icons/allfaqs-folders.svg'
import faq_image from '../../assets/icons/faq-image.svg'
import { BiSearchAlt } from "react-icons/bi"
import { getFaq } from '../../http/http';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import HeroSection from '../../components/HeroSection/HeroSection'
import Loader from '../../components/Loader/Loader'

const FaqTemplate = () => {
    const [faq, setFaq] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [thematics, setThematics] = useState([]);
    const [thematicSelected, setThematicSelected] = useState("")
    const [isLoading, setIsLoading] = useState(true)


    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }
    
    useEffect(() => {
            const loadFaqData = async () => {
                let thematicsArray = [];
                let uniqueThematics = [];
                const resp = await getFaq(thematicSelected)
                if (resp.response && resp.response.status !== 200) {
                } else {
        
                    setFaq(resp.data.data)
                    setIsLoading(false)
        
                    if (resp.data?.data?.length !== 0) {
        
                        resp.data?.data?.map((item, index) =>
                            thematicsArray.push(item.thematic)
                        )
                    }
                }
                if (thematics.length === 0) {
                    let temp = [...new Set(thematicsArray)];
                    temp.map((item, index) =>
                        uniqueThematics.push({ "id": index, "label": item }))
                    setThematics(uniqueThematics)
                }
            }
        loadFaqData()
    }, [thematicSelected, thematics?.length])

    return (
        <div>
            {!isLoading ?
                <>
                    <HeroSection />
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
                        {localStorage.getItem('secondMenu-active') !== "undefined" && <Breadcrumb.Item href="#">{(localStorage.getItem('secondMenu-active'))}</Breadcrumb.Item>}
                        <Breadcrumb.Item active>{JSON.parse(localStorage.getItem('active-menu'))?.name}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="faq-container">
                        <img src={faq_image} className="faq-image" alt='faq' loading="lazy"/>
                        <p className="faq-allfaq">
                            <img src={all_faq_folders} alt='all_faq' className="faq-allfaq-img" loading="lazy"/>
                            FOIRE AUX QUESTIONS - TOUTES LES FAQS
                        </p>
                        <div className="faq-input-container  flex-nowrap">
                            <BiSearchAlt className="faq-input-icon" />
                            <input className="faq-input" value={searchText} onChange={(e) => handleSearch(e)} placeholder="Rechercher..." />
                        </div>
                        <div className="faq-input-container  flex-nowrap">
                            <select onChange={(e) => setThematicSelected(e.target.value)} name='thematic' id="faq-thematic" className='w-100 thematic-select' style={{ height: "100%" }}>
                                <option value="" disabled="disabled" selected="true">Choisir Thématique</option>
                                <option value="">Tous</option>
                                {thematics.map(item => <option key={item.id} value={item.label}>{item.label}</option>)}
                            </select>
                        </div>
                    </div>

                    {faq?.length !== 0 && <FaqSection data={faq
                        .filter((q) => {
                            return q.question?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1
                        })} />}
                </>
                :
                <Loader />}
        </div>
    )
}

export default FaqTemplate