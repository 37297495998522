import React, { useEffect, useState } from 'react'
import "./InformationTemplate.css";
import { useNavigate, useParams } from 'react-router-dom';
import { getATypeOfArticles } from '../../http/http';
import Loader from '../../components/Loader/Loader';

const InformationTemplate = () => {

    const { menu } = useParams()

    const navigate = useNavigate()

    const [article, setArticle] = useState([]);
    // eslint-disable-next-line
    const [perPage, setPerPage] = useState(15);
    // eslint-disable-next-line
    const [pageNumber, setPageNumber] = useState(1);
    // eslint-disable-next-line
    const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const loadArticlesData = async () => {
            const resp = await getATypeOfArticles(menu, pageNumber)
            if (resp.response && resp.response.status !== 200) {
            } else {
                const perPageValue = resp?.data?.meta?.per_page
                setPerPage(perPageValue)
                const total = resp?.data?.meta?.total;
                setTotalPage(Math.ceil(total / perPageValue))
                setArticle(resp.data.data[0])
                setIsLoading(false)
            }
        }    
        loadArticlesData();
    }, [pageNumber, menu])


    return (
        <div>
            { !isLoading ?
            <>
            <div className="actuality__item-template__header">{menu.includes("_") ? menu.replaceAll("_", " ").toUpperCase() : menu.toUpperCase()}</div>
            <div className="actuality__item-template__content">
                {article?.converted_image["1296_864"] && <img src={article && article?.converted_image["1296_864"]} alt={article.title} loading="lazy"/>}

                <div className="text-content">
                    <div className="actuality__item__title">{article && article?.title}</div>
                    <div className="actuality__item__subtitle-content">
                        <div className="actuality__item__subtitle">{article && article?.sub_title}</div>
                    </div>
                    <div className="actuality__item__description ql-editor" dangerouslySetInnerHTML={{__html: (article && article?.content)}}>
                    </div>
                    <button onClick={() => navigate(`/`)} className="actuality__item__button">Retour</button>
                </div>
            </div>
            </>  
            :
            <Loader /> }
        </div>
    )
}
export default InformationTemplate