import React, { useState } from 'react'
import { participateToTraining } from '../../http/http'
import { ToastContainer, toast } from 'react-toastify';
import { convertDate, convertTime } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import "./FormationProgrammee.css"


const FormationProgrammee = ({ trainings }) => {

  const navigate = useNavigate();

  const handleParticipate = async (id) => {
    try {
      const response = await participateToTraining(id, localStorage.getItem("user_token"))
      if (response?.data?.success) {
        toast.success(response?.data?.message);

      } else {
        toast.error("Veuillez réessayer plus tard.");

      }
    } catch (error) {
      toast.error(error);

    }
  }

  return (
    <div>
      <ToastContainer />

      {trainings?.data?.length !== 0 ? (<table className='custom-table'>
        <thead className="">
          <tr className='table-row'>
            <th>Titre</th>
            <th>Description</th>
            <th>Date      </th>
            <th>Heure      </th>
            <th>Formateur      </th>
            <th>Auteur      </th>
            <th>Actions      </th>
          </tr>
        </thead>
        <tbody>
          {trainings?.data?.map((item, index) => <tr key={index} className='table-row'>
            <td>{item.title}</td>
            <td><div className='ql-editor' dangerouslySetInnerHTML={{__html: item.description}}></div></td>
            <td><strong>{convertDate(item.from_date)}</strong> au <strong>{convertDate(item.to_date)}</strong></td>
            <td><strong>{convertTime(item.from_time)}</strong> à <strong>{convertTime(item.to_time)}</strong></td>
            <td>{item.former}</td>
            <td>{item.created_by[0]?.name}</td>
            <td>
              <button className='table-button' onClick={() => navigate(`/espace-dpo/${item.uid}`)}>Détails</button>
              <button className='table-button' onClick={() => handleParticipate(item.uid)}>Participer</button>
            </td>
          </tr>)}

        </tbody>
      </table>) :
        <h1 className='mx-auto mt-20'>Aucune Donnée</h1>
      }
    </div>
  )
}

export default FormationProgrammee