import React, { useState, useEffect } from 'react'
import './DocumentationItemTemplate.css'
import { useNavigate, useParams } from 'react-router-dom'
import { getATypeOfArticles } from '../../http/http'
import Loader from '../../components/Loader/Loader'


const DocumentationItemTemplate = () => {
    const navigate = useNavigate()
    let { id, menu, page_number } = useParams();

    const [data, setData] = useState();
    const [activeMenu, setActiveMenu] = useState(null)
    const [displayPDF, setDisplayPDF] = useState(false)
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        if (activeMenu === null) {
            var temp = JSON.parse(localStorage.getItem('active-menu'))
            setActiveMenu(temp)
        }
    }, [activeMenu])

    useEffect(() => {
        const loadData = async () => {
            const resp = await getATypeOfArticles(menu, page_number)
            if (resp.response && resp.response.status !== 200) {
            } else {
                let element = resp.data.data.filter(elt => {
                    // eslint-disable-next-line
                    if (elt.id == id) {
                        return elt
                    } else {
                        return false
                    }
                })
                setData(element)
                setIsLoading(false)

            }
        }
        loadData();
        // eslint-disable-next-line
    }, [id, menu])

    return (
        <div>
            {!isLoading ?
                <>

                    <div className="documentation__item-template__header">
                        {/* eslint-disable-next-line */}
                        {activeMenu?.name[activeMenu?.name?.length - 1] == "s" ? activeMenu?.name.slice(0, -1) : activeMenu?.name}
                    </div>
                    <div className="documentation__item-template__content" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        {data[0]?.converted_image["1296_864"] && <div style={{ backgroundImage: `url(${data && data[0]?.converted_image["1296_864"]})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '300px', width: '300px' }}></div>}
                        <div className="text-content">
                            <div className="documentation__item__title">{data && data[0]?.title}</div>
                            {!displayPDF ? <>
                                <div className="documentation__item__subtitle-content">
                                    <div className="documentation__item__subtitle">{data && data[0]?.sub_title}</div>
                                </div>
                                <div className="actuality__item__description ql-editor" dangerouslySetInnerHTML={{ __html: (data && data[0]?.content) }}>
                                </div>
                            </>
                                :
                                <div style={{ width: "100%", height: "65%" }}>
                                    <object className="pdf-doc" data={data && data[0]?.document_url[0]} type="application/pdf">
                                        <iframe title={data && data[0]?.sub_title} src={`https://docs.google.com/viewer?url=${data && data[0]?.document_url[0]}&embbeded=true`} style={{ border: 0 }}></iframe>
                                    </object>
                                </div>
                            }
                            {data[0]?.document_url?.lenght ? <div className="buttons-content d-flex justify-content-between">

                                <a href={data && data[0]?.document_url[0]} download>
                                    <button className="documentation__item__download-button">Télécharger</button>
                                </a>
                                <button onClick={() => setDisplayPDF(!displayPDF)} className="documentation__item__download-button">{displayPDF ? "Afficher Contenu" : "Afficher Document"}</button>
                                <button onClick={() => navigate(`/documentations/${menu}`)} className="documentation__item__back-button">Retour</button>
                            </div> :
                                <div className="buttons-content d-flex justify-content-center">
                                    <button onClick={() => navigate(`/documentations/${menu}`)} className="documentation__item__back-button">Retour</button>

                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <Loader />}
        </div>)
}

export default DocumentationItemTemplate