import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Core from '../../appShell/Core';
import CoreItem from '../../appShell/CoreItem';
import DetailsFormation from '../../components/DetailsFormation/DetailsFormation';
import ActualityListTemplate from '../../templates/ActualityListTemplate/ActualityListTemplate';
import ForumDiscussionItemTemplate from '../../templates/ForumDiscussionItemTemplate/ForumDiscussionItemTemplate';
import ForumsDiscussionTemplate from '../../templates/ForumsDiscussionTemplate/ForumsDiscussionTemplate';
import Actualités from '../Actualités/Actualités';
import Auth from "../Auth/Auth";
import BreakNewsDetails from '../BreakNewsDetails/BreakNewsDetails';
import Contact from '../Contact/Contact';
import DetailsDemande from '../DetailsDemande/DetailsDemande';
import DetailsFormRequest from '../DetailsFormRequest/DetailsFormRequest';
import DynamiqueForm from '../Dynamique/DynamiqueForm';
import EspaceDPO from '../EspaceDPO/EspaceDPO';
import EspaceRT from '../EspaceRT/EspaceRT';
import FaireUneDemande from '../FaireUneDemande/FaireUneDemande';
import FormulaireCourrier from '../FormulaireCourrier/FormulaireCourrier';
import GlobalResearch from '../GlobalResearch/GlobalResearch';
import GlobalSearchItem from '../GlobalSearchItem/GlobalSearchItem';
import Home from '../Home/Home';
import MesDemandes from '../MesDemandes/MesDemandes';
import StatutDemande from '../StatutDemande/StatutDemande';


const Content = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
                <Routes>
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/query/:form_type/:query_id" element={<DetailsDemande />} />                   
                    <Route path="/actualites/:menu" element={<Core model={"actualites"}/>} />
                    <Route path="/actualites/:menu/:id/:page_number" element={<CoreItem type={'actualite'} />} />
                    <Route path="/communiques/:menu" element={<Core model={"communiques"}/>} />
                    <Route path="/communiques/:menu/:id/:page_number" element={<CoreItem type={'actualite'} />} />
                    <Route path="/documentations/:menu" element={<Core model={"documentations"}/>} />
                    <Route path="/documentations/:menu/:id/:page_number" element={<CoreItem type={'documentation'} />} />
                    <Route path="/videotheque/:menu" element={<Core model={"videotheque"}/>} />
                    <Route path="/videotheque/:menu/:id/:page_number" element={<CoreItem />} />
                    <Route path="/membres/:menu" element={<Core model={"membres"}/>} />
                    <Route path="/biographique/:menu" element={<Core model={"biographique"} />} />
                    <Route path="/mise_en_conformite/:menu" element={<Core model={"mise_en_conformite"} />} />
                    <Route path="/redirection_url/:menu" element={<Core model={"redirection_url"} />} />
                    <Route path="/pays_membres/:menu" element={<Core model={"pays_membres"}/>} />
                    <Route path="/prise_rdv" element={<Core model={"prise_rdv"}/>} />            
                    <Route path="/forums-discussions/:id" element={<ForumDiscussionItemTemplate />} />
                    <Route path="/actualites/actualites/:actualite_id" element={<Actualités />} />              
                    <Route path="redirection_url/faire-une-demande" element={<FaireUneDemande />} />
                    <Route path="redirection_url/statut-demande" element={<StatutDemande />} />
                    <Route path="redirection_url/mes-demandes" element={<MesDemandes />} />
                    <Route path="redirection_url/forums-discussions" element={<ForumsDiscussionTemplate />} />
                    <Route path="/faqs/:menu" element={<Core model={"faqs"}/>} />
                    <Route path="/actualites/:actualite_id" element={<ActualityListTemplate />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/breaknews/:breaknews_id/:page_number" element={<BreakNewsDetails />} />
                    <Route path="/vos-demarches" element={<Auth />} />
                    <Route path="/faire-une-demande" element={<FaireUneDemande />} />
                    <Route path="/formulaire-du-courrier" element={<FormulaireCourrier />} />
                    <Route path="/espace-rt" element={<EspaceRT />} />
                    <Route path="/espace-dpo/:id_formation" element={<DetailsFormation />} />
                    <Route path="/espace-dpo" element={<EspaceDPO />} />
                    <Route path="/statut-demande" element={<StatutDemande />} />
                    <Route path="/mes-demandes" element={<MesDemandes />} />
                    <Route path="/mes-demandes/:id_request" element={<DetailsFormRequest/>} />
                    <Route path="/formulaire/:id" element={<DynamiqueForm />} />
                    <Route path="/recherche-globale" element={<GlobalResearch />} />
                    <Route path="/recherche-globale/:recherche_id" element={<GlobalSearchItem />} />
                    < Route path="/" element={<Home />} >
                    </Route>
                    <Route path="*" element={<Home />} />
                </Routes>
        </div>
    )
}

export default Content
