import React, {useState, useEffect} from 'react';
import FaireUneDemandeCard from "../../components/FaireUneDemandeCard/FaireUneDemandeCard";
import "./FaireUneDemande.css";
import { useNavigate } from "react-router-dom";
import {getAllFormTypes, getForm} from '../../http/http';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from '../../components/Pagination/Pagination';
import { FaTimes, FaSearch, FaCheckCircle, FaArrowLeft } from "react-icons/fa"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const FaireUneDemande = () => {

  const navigate = useNavigate();
  const [demarches, setDemarches] = useState(null);
  const [mainDemarches, setMainDemarches] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayingSubtypes, setIsDisplayingSubtypes] = useState(false);
  const [formulaire, setFormulaire] = useState(null);
  const [standardSections, setStandardSections] = useState([]);
  const [annexeSections, setAnnexeSections] = useState([]);
  const [pjSections, setPJSections] = useState([]);
  const [demarchesToDisplay, setDemarchesToDisplay] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const handleInput = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value.trim() != ""){
      const results = demarches.filter((demarche) => {
        return demarche?.name?.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setDemarchesToDisplay(results);
    } else {
      setDemarchesToDisplay(paginateArray(demarches, 4)[0])
    }
  }

  const loadForm = async (demarche) => {
    setIsLoading(true);
    const resp = await getForm(demarche);
    if (resp.response && resp.response.status !== 200){
    } else {
      await setFormulaire(resp?.data?.data);
      const standardSections = resp?.data?.data?.sections?.Standard ?? [];
      const annexesSections =  resp?.data?.data?.sections?.Annexe ?? [];
      const pjSections =  resp?.data?.data?.sections?.Pj ?? [];

      setStandardSections(standardSections);
      setAnnexeSections(annexesSections);
      setPJSections(pjSections);
    }
    setIsLoading(false);
  }
  
  const handleClearBtn = () => {
    setSearchVal('');
    setDemarchesToDisplay(paginateArray(demarches, 4)[0])
  }

  const loadFormTypes = async () => {
    const resp = await getAllFormTypes();
    setDemarches(resp.data?.data);
    setDemarchesToDisplay(paginateArray(resp?.data?.data, 4)[0])
    setMainDemarches(resp.data?.data);
  }

  useEffect(() => {
    loadFormTypes();
  }, [])

  const Loading =()=>
    <div className="apdp-form-loading-main-div">
        <div className="apdp-form-loading">
            <div></div>
            <div></div>
        </div>
        <p>Chargement...</p>
    </div>

  if (!demarches){
    return <Loading />
  }

  function paginateArray(array, itemsPerPage) {
    const paginatedArray = [];
    let pageIndex = 0;
  
    while (pageIndex < array.length) {
      paginatedArray.push(array.slice(pageIndex, pageIndex + itemsPerPage));
      pageIndex += itemsPerPage;
    }
  
    return paginatedArray;
  }

  return (
    <div
      style={{
        backgroundColor: "#E7E7E7",
        paddingTop: "40px",
        paddingBottom: "40px",
      }}
    >
      <Breadcrumb style={{ marginTop: '-40px', fontFamily: 'Montserrat' }}>
          <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Vos démarches</Breadcrumb.Item>
          <Breadcrumb.Item active>Faire ou modifier une demande</Breadcrumb.Item>
      </Breadcrumb>

      <div id="faire-une-demande" className="faire-une-demande" style={{ marginTop: 100 }}>
        <div className="faire-une-demande-container">
          <p className="vos-demarches-text">Vos Démarches</p>
          <div className='demarches-search-input' style={{ fontFamily: 'Montserrat' }}>
            <div className='input-wrap'>
              <FaSearch color='#4385f6' size={20}/>
              <label for="demarche-search" id="input-label">
                Recherche
              </label>
              <input onChange={handleInput} value={searchVal} type="text" name="demarche-search" id="demarche-search" placeholder="Rechercher une démarche ici..."/>
              <FaTimes onClick={handleClearBtn} color='#4385f6' size={20}/>
            </div>
          </div>
          { isDisplayingSubtypes ? <button
              className="btn btn-primary"
              onClick={() => {
                setDemarches(mainDemarches);
                setDemarchesToDisplay(paginateArray(mainDemarches, 4)[0]);
                setIsDisplayingSubtypes(false);
              }}
              style={{ 
                fontSize: 18,
                margin: 10,
                float: 'inline-start'
              }}
            >
              <FaArrowLeft color='white' style={{ marginRight: 10 }} /> 
              Revenir 
            </button> : null }
          <div className="faire-une-demande-cards-container row">
            {demarchesToDisplay && demarchesToDisplay.map((demarche) => {
              return demarche.sub_types && demarche.sub_types.length > 0 ? (
                <div className="col-sm-6 col-xs-12" key={demarche.id} style={{ paddingRight: 15, paddingLeft: 15 }}>
                  <FaireUneDemandeCard
                    handleCardClicked={() => {
                      setDemarches(demarche.sub_types);
                      setDemarchesToDisplay(paginateArray(demarche.sub_types, 4)[0])
                      setIsDisplayingSubtypes(true);
                    }}
                    title={demarche.name}
                    description={demarche?.description ?? "Votre description ici"}
                  />
                </div>
              ) : (
                <Popup  
                  key={demarche.id}
                  modal
                  nested
                  onOpen={() => {
                    setFormulaire(null);
                    setStandardSections([]);
                    setAnnexeSections([]);
                    setPJSections([]);
                    loadForm(demarche.slug);
                  }}
                  trigger={
                    <div className="col-sm-6 col-xs-12" style={{ paddingRight: 15, paddingLeft: 15 }}>
                      <FaireUneDemandeCard
                        title={demarche.name}
                        description={demarche?.description ?? "Votre description ici"}
                      />
                    </div>
                  }
                  contentStyle={{ borderRadius: 5, }}
                  position="right center"
                >
                  <div className="card-body container" style={{ fontFamily: 'Montserrat', fontSize: 14, textAlign: 'center', padding: 50, overflow: 'auto', maxHeight: 500, zIndex: 1000, }}>
                    <FaTimes onClick={()=> window.location.reload()} style={{ float: 'right', position: 'relative', top: -50, cursor: 'pointer' }}/>
                    { formulaire ? <><div className="header" style={{ margin: "10px 0px 20px 0px", textAlign: 'center', fontSize: 18, wordWrap: 'break-word' }}>
                      {formulaire?.name}
                    </div>
                    <ul className="infos" style={{ textAlign: 'left', marginBottom: 10, }}>
                      <li>
                        <FaCheckCircle color='#4385f6' /> <span>Standards : </span> <b>{standardSections.length}</b>
                        <ol style={{ listStyle: 'decimal-leading-zero', padding: '10px 30px' }}>
                          {standardSections.map((section) => (
                            <li> {section?.name} </li> 
                          ))}
                        </ol>
                      </li>
                      <li>
                        <FaCheckCircle color='#4385f6' /> <span>Annexes : </span> <b>{annexeSections.length}</b>
                        <ol style={{ listStyle: 'decimal-leading-zero', padding: '10px 30px' }}>
                          {annexeSections.map((section) => (
                            <li> {section?.name} </li> 
                          ))}
                        </ol>
                      </li>
                      <li>
                        <FaCheckCircle color='#4385f6' /> <span>Pièces jointes : </span> <b>{pjSections.length}</b>
                        <ol style={{ listStyle: 'decimal-leading-zero', padding: '10px 30px' }}>
                          {pjSections.map((section) => (
                            <li> {section?.name} </li> 
                          ))}
                        </ol>
                      </li>
                    </ul>
                    <div style={{ textAlign: 'left' }}>
                      <div className='form-description' dangerouslySetInnerHTML={{ __html: formulaire?.detail }}></div>
                    </div>
                    <div className="actions">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate(`/formulaire/${demarche.slug}`)
                        }}
                        style={{ 
                          fontSize: 18,
                          margin: 10,
                          position: 'relative',
                          top: 20
                        }}
                      >
                        Commencer
                      </button>
                    </div></> : (isLoading ? <Loading/> : <>
                      <div className="header" style={{ margin: "10px 0px 20px 0px", textAlign: 'center', fontSize: 18 }}>{demarche?.name}</div>
                      <div style={{ textAlign: 'center' }}>
                        <p>Le formulaire que vous recherchez est introuvable...</p>
                      </div>
                      <div className="actions">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            window.location.reload();
                          }}
                          style={{ 
                            fontSize: 18,
                            margin: 10,
                            position: 'relative',
                            top: 30
                          }}
                        >
                          Fermer
                        </button>
                      </div>
                    </>) }
                  </div>
                </Popup>
              );
            })}
          </div>

          {/* Display pagination */}
          {demarches?.length > 4 && searchVal.trim() == '' ? <Pagination
                changePage={(e)=>{
                  setDemarchesToDisplay(paginateArray(demarches, 4)[e.selected])
                }}
                pageCount={Math.round(demarches?.length / 4)}
                perPage={4}
            />: <div></div>
          }
        </div>
      </div>
    </div>
  );
};

export default FaireUneDemande;
