import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from 'react';
import ReactGA from "react-ga";
import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css';
import './App.css';
import { getMenus } from './http/http';
import Content from './layouts/Content/Content';
import Footer from './layouts/Footer/Footer';
import Header from './layouts/Header/Header';

function App() {

  const [menus, setMenus] = useState([])
  const [firstMenus, setFirstMenus] = useState([])
  const [secondMenus, setSecondMenus] = useState([])
  const TRACKING_ID = "G-XC34G27R1H";

  ReactGA.initialize(TRACKING_ID);


  const fetchMenus = async () => {
    let first = []
    let second =[]
    const request = await getMenus()
    if(request?.data) {
      setMenus(request.data.data)
      first = request.data?.data.filter(item => item.level == 1);
      second = request.data?.data.filter(item => item.level == 2);
      setFirstMenus(first)
      setSecondMenus(second)
    }
  }

  useEffect(() => {
    fetchMenus();
  }, [])

  return (
    <div className="App">
      {firstMenus.length == 0 && secondMenus.length == 0 ? <div style={{ backgroundColor: '#337ab7', width: '100%', height: '100%', position: 'fixed', display: 'flex', alignItems: 'center'}}>
        <div style={{ alignItems: 'center', width: '100%'}}>
  
          <p style={{
              color: 'white'
            }}
          >
            Chargement en cours...
          </p>
        </div>
      </div> : null }
      {firstMenus.length != 0 && secondMenus.length != 0 ? <Header firstMenus={firstMenus} secondMenus={secondMenus} /> : null }
      {firstMenus.length != 0 && secondMenus.length != 0 ? <Content/> : null }
      {firstMenus.length != 0 && secondMenus.length != 0 ? <Footer/> : null }
    </div>
  );
}

export default App;
