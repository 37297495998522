/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import './ActualityItemTemplate.css'
import { useNavigate, useParams } from 'react-router-dom'
import { getATypeOfArticles } from '../../http/http'
import Loader from '../../components/Loader/Loader'


const ActualityItemTemplate = () => {
    const navigate = useNavigate()
    let { id, menu, page_number } = useParams();

    const [data, setData] = useState();
    const [activeMenu, setActiveMenu] = useState(null)
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        if (activeMenu === null) {
            var temp = JSON.parse(localStorage.getItem('active-menu'))
            setActiveMenu(temp)
        }
    }, [activeMenu])

    useEffect(() => {
        loadData();
    }, [id, menu])

    const loadData = async () => {
        const resp = await getATypeOfArticles(menu, page_number)
        if (resp.response && resp.response.status !== 200) {
        } else {
            let element = resp.data.data.filter(elt => {
                // eslint-disable-next-line
                if (elt.id == id) {
                    return elt
                } else {
                    return false
                }
            })
            setData(element)
            setIsLoading(false)
        }
    }
    return (
        <div>
            { !isLoading ?
            <>
            <div className="actuality__item-template__header">{activeMenu?.name}</div>
            <div className="actuality__item-template__content" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                { data[0]?.converted_image["1296_864"] && <div alt={data[0]?.title} style={{ backgroundImage: `url(${data && data[0]?.converted_image["1296_864"]})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '300px', width: '420px' }}></div> }
                <div className="text-content">
                    <div className="actuality__item__title">{data && data[0]?.title}</div>
                    <div className="actuality__item__subtitle-content">
                        <div className="actuality__item__subtitle">{data && data[0]?.sub_title}</div>
                    </div>
                    <div className="actuality__item__description mb-5 ql-editor" dangerouslySetInnerHTML={{ __html: (data && data[0]?.content) }}>
                    </div>
                </div>
                <button onClick={() => navigate(`/actualites/${menu}`)} className="actuality__item__button mb-3">Retour</button>
            </div>
            </>         
            :
            <Loader /> }
        </div>
    )
}

export default ActualityItemTemplate
