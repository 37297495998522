import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import dots from "../../assets/icons/dots.svg";
import ActualiteCarousel from "../../components/ActualiteCarousel/ActualiteCarousel";
import ShortcutCard from "../../components/ShortcutCard/ShortcutCard";
import UserTypeCard from "../../components/UserTypeCard/UserTypeCard";
import "./Home.css";
import { getArticlesResearched, getATypeOfArticles } from "../../http/http";


const Home = () => {
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)

  const handleClickCard = (cardClicked) => {
    navigate(`/${cardClicked}`);
  }
   
  useEffect(() => {
      localStorage.removeItem('active-menu')
  }, []);

    const [search, setSearch] = React.useState("");
    const [articlesResearched, setArticlesResearched] = React.useState();
  

    const handleResearch = async () => {
      const response = await getArticlesResearched(search)
      if (response.response && response.response.status !== 200) {
      } else {
        setArticlesResearched(response.data)
      }
    }
    const loadShortcutsLink = async () => {
      const resp = await getATypeOfArticles("liens_utiles", 1)
      if (resp.response && resp.response.status !== 200) {
      } else {
        const perPageValue = resp?.data?.meta?.per_page
        setPerPage(perPageValue)
        const total = resp?.data?.meta?.total;
        setTotalPage(Math.ceil(total / perPageValue))
        setArticles(resp.data.data)
        setIsLoading(false)
      }
  
    }

    React.useEffect(() => {
      handleResearch()
    }, [search])
    React.useEffect(() => {
      loadShortcutsLink();
    }, [])

    return (
      <div className={"Home"}>

        <ActualiteCarousel />
        <div className={"d-flex flex-wrap user-type-card-container"}>
          <UserTypeCard
            handleClick={() => handleClickCard("actualites/mes_droits")}
            type={"right"}
            description={"Si un responsable de traitement estime qu’une demande..."}
          />
          <UserTypeCard
            handleClick={() => handleClickCard("actualites/mes_devoirs")}
            type={"duty"}
            description={"Vous avez la possibilité de bloquer l’accès de..."}
          />
          <UserTypeCard
            type={"demand"}
            description={"Tout citoyen peut s’adresser à l’APDP pour..."}
          />
          <UserTypeCard
            handleClick={() => handleClickCard("documentations/formulaires")}
            type={"forms"}
            description={"Pour la mise en conformité avec le régime de protection des données personnelles au Bénin..."}
          />
        </div>
        <Row className={"d-flex justify-content-end pr-5"}>
          <img className={"dots mr-5"} alt="dots" src={dots} loading="lazy"/>
        </Row>
        <br />
        <div className={"forms-section"}>
          <p className={"form-section-subtitle"}>Liens Rapides</p>
          <div className={"row"}>
            <div className={"col-sm-8 col-xs-12 mx-auto"} style={{ width: '80%' }}>
              <div className={"d-flex flex-wrap justify-content-evenly"}>
                {articles.length && articles.map(link => 
                <ShortcutCard className="mx-auto" ind={2} label={link.title} link={link.sub_title} />
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Home;
