import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertDate, convertTime } from '../../helpers/utils';
import { getOneTraining } from '../../http/http';
import Loader from '../Loader/Loader';
import "./DetailsFormation.css";

const DetailsFormation = () => {

    const { id_formation } = useParams();
    const [data, setData] = useState(null)

    const [isLoading, setIsLoading] = useState(true);




    useEffect(() => {
        const getFormationData = async () => {
            try {
                const response = await getOneTraining(id_formation, localStorage.getItem("user_token"))
                if (response?.data?.success) {
                    setData(response?.data?.data)
                    setIsLoading(false)
                } else {
                }
            } catch (error) {
            }
        }

        getFormationData();

    }, [id_formation])

    return (
        <div style={{ backgroundColor: "#E2E2E2", paddingTop: "40px", paddingBottom: "40px" }}>
            {!isLoading ?
                <>
                    <div className="espace-dpo-container">
                        <p className="espace-dpo-title">ESPACE DPO - Détails Formation</p>
                        <div className="espace-dpo-cards-container row d-flex ">

                        <div className='espace-dpo-content  col-sm-6 col-xs-12 p-3'>

                                <div className="text-center title">Informations</div>
                                <div className="row d-flex mb-1">
                                    <div className="col-6 col-xs-12 info-label">Titre:</div><div className="col-6 col-xs-12 info-content">{data?.title}</div>
                                  </div>
                                <div class="row d-flex mb-1">
                                    <div class="col-6 col-xs-12 info-label">Description: </div><div class="col-6 col-xs-12 info-content" dangerouslySetInnerHTML={{__html: data?.description}}></div>
                                </div>
                                <div className="row d-flex mb-1">
                                    <div className="col-6 col-xs-12 info-label">Formateur:</div><div className="col-6 col-xs-12 info-content">{data?.former}</div>
                                </div>
                                <div className="row d-flex mb-1">
                                    <div className="col-6 col-xs-12 info-label">Date:</div><div className="col-6 col-xs-12 info-content"><strong>{convertDate(data?.from_date)}</strong> au <strong>{convertDate(data?.to_date)}</strong></div>
                                </div>
                                <div className="row d-flex mb-1">
                                    <div className="col-6 col-xs-12 info-label">Heure:</div><div className="col-6 col-xs-12 info-content"><strong>{convertTime(data?.from_time)}</strong> à <strong>{convertTime(data?.to_time)}</strong></div>
                                </div>
                                <div className="row d-flex mb-1">
                                    <div className="col-6 col-xs-12 info-label">Auteur:</div><div className="col-6 col-xs-12 info-content">{data?.created_by[0]?.name}</div>
                                </div>

                                <div className="text-center title">Supports</div>
                                {data?.supports?.length !== 0 ? (<div className='custom-overflow  w-100'><table className='custom-table'>
                                    <thead className="">
                                        <tr className='table-row'>
                                            <th>Titre</th>
                                            <th>Description</th>
                                            <th>Auteur      </th>
                                            <th>Actions      </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.supports?.map((item, index) => <tr key={index} className='table-row'>
                                            <td>{item.title}</td>
                                            <td>{item.description}</td>
                                            <td>{item.author}</td>
                                            <td>

                                                {item.media_type === "livre" ? <a href={item?.media_url[0]} download target='_blank' rel="noreferrer">
                                                    <button
                                                        className="documentation__item__download-button"
                                                    >Télécharger</button>
                                                </a> :
                                                    <a href={item?.media_url[0]} download target='_blank' rel="noreferrer">
                                                        <button
                                                            className="documentation__item__download-button"
                                                        >Visualiser</button></a>
                                                }
                                            </td>
                                        </tr>)}

                                    </tbody>
                                </table></div>) :
                                    <h1 className='mx-auto mt-20'>Aucun Support</h1>
                                }
                        </div>
                        </div>
                    </div> </>
                :
                <Loader />}
        </div>
    )
}

export default DetailsFormation