import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';
import { cancelSubmission, getFormById, getMediaFile, getRequestedQuery } from '../../http/http';
import './DetailsFormRequest.css';

export default function DetailsFormRequest() {
    const [formulaire, setFormulaire] = useState(null);
    const [query, setQuery] = useState({});
    const [loading, setLoading] = useState(true);
    const [answersForm, setAnswersForm] = useState([]);
    const [refNumber, setRefNumber] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var signatures = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        R0lGODlh: "image/gif",
        iVBORw0KGgo: "image/png",
        "/9j/": "image/jpg"
    };

    function detectMimeType(b64) {
        for (var s in signatures) {
            if (b64.indexOf(s) === 0) {
            return signatures[s];
            }
        }
    }

    function downloadMultipleFiles(links) {
        links.forEach(link => {
            const anchor = document.createElement('a');
            anchor.href = link.url;
            anchor.download = link.fileName;
            document.body.appendChild(anchor);
            // Simulez un clic pour déclencher le téléchargement
            anchor.click();
            // Retirez l'élément du DOM après le téléchargement
            document.body.removeChild(anchor);
        });
    }

    const loadForm = async () => {
        const formId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        const respForm = await getFormById(formId);
        if (respForm.response && respForm.response.status !== 200){
        } else {
            setFormulaire(respForm.data);
            var url = new URL(window.location.href);
            const refNumber = url.searchParams.get("saved_num");
            setRefNumber(refNumber);
            const queryResponse = await getRequestedQuery(refNumber);
            if(!queryResponse.data.data.id){
                alert('Rien à afficher!!!');
                window.history.back();
            } else {
                setQuery(queryResponse.data.data);
                var answersFormArray = [];
                var questionsArray = [];
                const allSectionIds = Object.values(respForm.data.data.sections).reduce((acc, val) => acc.concat(val), []);
                for (let iterator = 0; iterator < allSectionIds.length; iterator++) {
                    const section = allSectionIds[iterator];
                    for (let newIter = 0; newIter < section.questions.length; newIter++) {
                        let question = section.questions[newIter];
                        question.index = allSectionIds.indexOf(section);
                        if (!questionsArray.includes(question)){
                            questionsArray.push(question);
                        }
                    }
                }
                for (let index = 0; index < queryResponse.data.data.answers.length; index++) {
                    const answer = queryResponse.data.data.answers[index];
                    for (let newIdex = 0; newIdex < questionsArray.length; newIdex++) {
                        const question = questionsArray[newIdex];
                        var files = [];
                        if (answer.question_id == question.id) {
                            if (answer?.answer_file != null && answer?.answer_file.length > 0){
                                const processFiles = async () => {
                                    for (const file of answer?.answer_file) {
                                        try {
                                            const mediaFileResponse = await getMediaFile(answer?.id, file);
                                            const base64String = mediaFileResponse?.data?.data?.content;
                                            const fileLink = `data:${detectMimeType(base64String)};base64,` + base64String;
                                
                                            // Vérifiez si le fichier n'est pas déjà dans la liste avant de l'ajouter
                                            if (!files.some(existingFile => existingFile.url === fileLink)) {
                                                files.push({
                                                    url: fileLink,
                                                    fileName: file,
                                                });
                                            }
                                        } catch (error) {
                                            console.error('Erreur lors du traitement des fichiers', error);
                                        }
                                    }
                                };
                                await processFiles();
                                answersFormArray.push({'question_id': question.id, 'question': question.name, 'answer': answer.answer_text, 'files': Array.from(new Set(files)), 'id': question.index })
                            } else {
                                answersFormArray.push({'question_id': question.id, 'question': question.name, 'answer': answer.answer_text, 'id': question.index})
                            }
                        }
                    }
                }
                setAnswersForm(answersFormArray);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        loadForm();
    }, [])

    function printForm() {
        var formContent = document.getElementById('recapContent').outerHTML;
        var printWindow = window.open('', '', 'height=400,width=800');
        printWindow.document.write('<html><head><title>APDP Formulaire</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(formContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    const Loading =()=>
    <div className="apdp-form-loading-main-div">
        <div className="apdp-form-loading">
            <div></div>
            <div></div>
        </div>
        <p>Chargement...</p>
    </div>

    if (!formulaire) {
        return <Loading/>
    }

    return (<>
        <Modal style={{ fontFamily: 'Montserrat' }} animation={false} centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Annuler la demande</Modal.Title>
            </Modal.Header>
            <Modal.Body>Vous êtes sur le point d'annuler votre demande, voulez-vous continuer ?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Fermer
            </Button>
            <Button style={{ backgroundColor: "rgb(220, 53, 69)", borderColor: 'white' }} variant="primary" onClick={async () => {
                handleClose();
                const cancellingReq = await cancelSubmission(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`), {});
                if (cancellingReq?.data?.success){
                    toast.success("Soumission annulée avec succès !!!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error("Erreur lors de l'annulation de la soumission !!!")
                }
            }}>
                Confirmer
            </Button>
            </Modal.Footer>
        </Modal>
        <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px", fontFamily: 'Montserrat'}}>
            <Breadcrumb style={{ width: "100%", marginTop: -5 }}>
                <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
                <Breadcrumb.Item href="/faire-une-demande">Vos démarches</Breadcrumb.Item>
                <Breadcrumb.Item href="/mes-demandes">Mes demandes</Breadcrumb.Item>
                <Breadcrumb.Item active>Détails de la demande</Breadcrumb.Item>
            </Breadcrumb>
            <Form className="form-style">
                <div className="row" style={{ alignItems: 'center', marginBottom: '30px', }}>
                    <div className='col-sm-8' style={{ 
                        flexDirection: "column", 
                        display: "flex", 
                        alignItems: "flex-start"
                     }}>
                        <h2 style={{ fontSize: 'large', textAlign: 'left', fontFamily: 'Montserrat', }}>Nom de la demande: <span style={{ fontWeight: 'normal' }}>{formulaire.data.name}</span> </h2>
                        <h2 style={{ fontSize: 'large', float: 'left', fontFamily: 'Montserrat', }}>N° de dépôt: <span style={{ fontWeight: 'normal' }}>{refNumber}</span></h2>
                        <h2 style={{ fontSize: 'large', textAlign: 'left', fontFamily: 'Montserrat', }}>Statut de la demande: <span style={{ fontWeight: 'normal' }}>
                                {query.status == "pending" && "EN ATTENTE" }
                                {query.status == "submitted" && "SOUMIS" }
                                {query.status == "rejected" && "REJETE" }
                                {query.status == "approved" && "APPROUVE" }
                                {query.status == "cancelled" && "ANNULE" }
                                {query.status == "additional_info" && "INCOMPLET" }
                            </span>
                        </h2>
                    </div>
                    <div className="col-sm-4">
                        <Button className="auth-form-btn" style={{ margin: "0px", padding: "10px", width: "100%", borderRadius: "5px", fontSize: "15px", backgroundColor: "#ffbe00", borderColor: '#ffbe00', color: "white", fontWeight: "bold", float: 'right' }} onClick={(e) =>printForm()}>
                            Imprimer
                        </Button>
                        {query.status == "pending" && <Button onClick={handleShow} className="auth-form-btn" style={{ margin: "10px 0px", padding: "10px", width: "100%", borderRadius: "5px", fontSize: "15px", backgroundColor: "#dc3545", borderColor: '#dc3545', color: "white", fontWeight: "bold", float: 'right' }}>
                            Annuler la demande
                        </Button> }
                    </div>
                </div>
                { loading ? <Loading/> : <div id='recapContent' className="row" style={{ textAlign: 'left' }}>
                    <div style={{ marginBottom: '30px' }}>
                        <table style={{ maxWidth: 'none', width: '100%', border: '2px solid rgb(9, 61, 98)' }}>
                            {answersForm.map((answer) => (
                                        <tr style={{ border: '2px solid rgb(9, 61, 98)' }}>
                                            <td style={{ backgroundColor: 'rgb(9, 61, 98)', color: 'white',padding: '10px', width: '50%', fontFamily: 'Montserrat', }}>{answer.question}</td>
                                            <td style={{ padding: '10px', width: '50%', fontFamily: 'Montserrat', }}>{answer.answer ? answer.answer : <button  type='button' className='btn btn-primary' onClick={()=> downloadMultipleFiles(answer?.files)}>Télécharger</button>}</td>
                                        </tr>
                                ))
                            }
                        </table>
                    </div>
                </div> }
            </Form>
        </div>
        </>
    )
}


