import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import apdp_logo from "../../assets/images/logo-apdp.svg";
import { getATypeOfArticles } from '../../http/http';
import './Header.css';
import NavBar from './NavBar';

const Header = (props) => {


    const { firstMenus, secondMenus } = props;
    const [breaknews, setBreaknews] = useState([]);

    const navigate = useNavigate()

    const [state, setState] = useState(
        {
            showSub: "",
            showSubSub: ""
        }
    )

    const [activeMenu, setActiveMenu] = useState("")

    const handleViewBreaknews = (id) => {
        window.location.replace(`/breaknews/${id}`)
    }

    const loadBreaknewsData = async () => {
        const resp = await getATypeOfArticles("breaknews")
        if (resp.response && resp.response.status !== 200) {
             console.log("data error ", resp.response)
        } else {
            setBreaknews(resp.data?.data.reverse())
        }
    }


    useEffect(() => {
        loadBreaknewsData();
    }, [])


    const goHome = () => {
        window.location.replace("/");
    }

    const session_logout = () => {
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_token');
        localStorage.removeItem('is_dpo');
        localStorage.removeItem('is_rt');
        window.location = '/';
    }



    return (
        <>
            <React.Fragment>
                <div id={"header"}>
                    <div className="hwrap">
                        <div className="hmove">
                            {
                                breaknews && breaknews?.map((item, index) =>
                                (
                                    <p key={item.id} onClick={() => handleViewBreaknews(item.id)}
                                         style={{ color: '#fff' }}
                                        className="hitem  mx-5 ql-editor"
                                        dangerouslySetInnerHTML={{ __html: (item?.content) }}
                                        >
                                        </p>
                                )
                                )}
                        </div>
                    </div>
                    <div className="header-block-2">
                        <div className='header-logo' onClick={goHome} style={{ backgroundImage: `url('${apdp_logo}'`, cursor: "pointer" }}>
                        </div>
                        <div className="d-md-flex flex-wrap header-block-2-div-2 d-flex flex-row justify-content-around">
                            {firstMenus?.sort((a, b) => (a.order > b.order) ? 1 : -1).map((sub, index) =>
                                <>{sub.children?.length === 0 ?
                                    <div
                                        key={sub.name}
                                        className="col"
                                        style={{ color: "#292929", font: "normal normal normal 20px/35px Montserrat", cursor: "pointer", width: 'fit-content', flex: 'none', padding: '2px 10px' }}
                                        onClick={() => {
                                            localStorage.setItem('active-menu', JSON.stringify(sub))
                                            navigate(`/${sub?.model}/${sub?.slug}`)
                                        }
                                        }
                                    > 
                                    {sub.name.toUpperCase()}
                                    </div> :
                                    <div className='dropdown'
                                        onMouseLeave={() => setState({ ...state, showSub: "" })}
                                        onMouseOver={() => setState({ ...state, showSub: sub.name })}
                                    >
                                        <div className="dropdown-toggle drop-class" style={{ font: "normal normal normal 20px/35px Montserrat", cursor: "pointer", width: 'fit-content', flex: 'none', padding: '2px 10px' }}

                                            type="" data-toggle="dropdown">{sub.name.toUpperCase()}
                                        </div>
                                        <ul style={{width: 'fit-content', paddingRight: '8px'}} className={state.showSub == sub.name ? "dropdown-menu show pop-up" : "dropdown-menu"}>
                                            {sub.children?.sort((a, b) => (a.order > b.order) ? 1 : -1).map((subItem, index) =>
                                                <li key={subItem.name} style={{ cursor: 'pointer' }}><a tabIndex="-1" onClick={() => {
                                                    setActiveMenu(subItem.parent_id)
                                                    localStorage.setItem('active-menu', JSON.stringify(sub))
                                                    navigate(`/${subItem?.model}/${subItem?.slug}`)
                                                }}>{subItem.name}</a></li>)}

                                        </ul>
                                    </div>}
                                </>
                            )}
                        </div>

                        {localStorage.getItem("user_token") ? <div>
                            <Button color="danger" style={{ margin: '20px' }} onClick={session_logout} className="client-espace-button"><h6 style={{ margin: '0px' }}>Déconnexion</h6></Button>
                        </div> : <div></div>}
                    </div>

                    <div className="header-block-3">
                        <NavBar secondMenus={secondMenus.sort((a, b) => (a.order > b.order) ? 1 : -1)} />
                    </div>
                </div>
            </React.Fragment>
        </>
    )
}

export default Header
