import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getATypeOfArticles } from '../http/http'
import ActualityListTemplate from '../templates/ActualityListTemplate/ActualityListTemplate'
import DocumentationListTemplate from '../templates/DocumentationListTemplate/DocumentationListTemplate'
import FaqTemplate from '../templates/FaqTemplate/FaqTemplate'
import ForumsDiscussionTemplate from '../templates/ForumsDiscussionTemplate/ForumsDiscussionTemplate'
import InformationTemplate from '../templates/InformationTemplate/InformationTemplate'
import MemberItemTemplate from '../templates/MemberItemTemplate/MemberItemTemplate'
import MemberListTemplate from '../templates/MemberListTemplate/MemberListTemplate'
import { PaysMembresListTemplate } from '../templates/PaysMembresListTemplate/PaysMembresListTemplate'
import RendezVousTemplate from '../templates/RendezVousTemplate/RendezVousTemplate'
import VideothequeTemplate from '../templates/VideothequeTemplate/VideothequeTemplate'

const Core = ({model}) => {

  const [redirection, setRedirection] = useState("");
  const { menu } = useParams()


  
  useEffect(() => {
    if(model === "redirection_url"){
    const loadRedirectionUrlData = async () => {
      const resp = await getATypeOfArticles(menu, 1)
      if (resp.response && resp.response.status !== 200) {
      } else {
          window.location.href = resp.data.data[0].title;
          setRedirection(resp.data?.data[0]?.title);
      }
  }   
    loadRedirectionUrlData();
  }
}, [menu, model])

  return (
    <div>
        {model === "actualites" && <ActualityListTemplate />}
        {model === "communiques" && <ActualityListTemplate />}
        {model === "documentations" && <DocumentationListTemplate />}
        {model === "membres" && <MemberListTemplate />}
        {model === "pays_membres" && <PaysMembresListTemplate />}
        {model === "videotheque" && <VideothequeTemplate />}
        {model === "biographique" && <MemberItemTemplate />}
        {model === "faqs" && <FaqTemplate />}
        {model === "mise_en_conformite" && <InformationTemplate />}
        {model === "prise_rdv" && <RendezVousTemplate />}
        {model === "forums" && <ForumsDiscussionTemplate />}
        {model === "redirection_url" && <h1 className='m-auto p-5'>Chargement...</h1>}
    </div>
  )
}

export default Core