import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FormGroup, Input, Label } from 'reactstrap';
import './CustomInput.css';

export default function CustomInput({field, updateValue}) {
    const [currentRadio, setRadio] = useState(null)
    const [currentCheckBoxes, setCheckboxes] = useState([])
    let fileInputsData = [];

    function fileListFrom (files) {
        const b = new ClipboardEvent("").clipboardData || new DataTransfer()
        for (const file of files) b.items.add(file)
        return b.files
    }

    const readBlob = (file, event) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        const invalidFeedbackNode = document.getElementById(`invalid-feedback-${event.target.id}`);
        let error = false;

        reader.onload = file => {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];

            if (!allowedTypes.includes(file.target.result.split(';')[0].replace('data:',''))) {
                error = true
                event.target.files = fileListFrom([]);
                invalidFeedbackNode.innerText = 'Seuls les fichiers images (JPEG, PNG, GIF) et les PDF sont autorisés.';
                invalidFeedbackNode.style.display = 'initial';
            }
            
            if (!error){
                fileInputsData.push(file.target.result);
                invalidFeedbackNode.style.display = 'none';
                updateValue({
                    answer_file: fileInputsData,
                    choice: field?.option_choices[0]?.id,
                    question: field.id
                });
                resolve(file);
            }
        };

        reader.onerror = () => {
            invalidFeedbackNode.innerText = 'La lecture du fichier a échoué.';
            invalidFeedbackNode.style.display = 'initial';
        };

        reader.readAsDataURL(file);
    }); 

    if(field.input_type.element === 'select' || field.input_type.element === 'multi-select') {
        return (
            <>
                <FormGroup id='input_div' style={{ textAlign: 'left', margin: "20px 0px", }}>
                    <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "14px", color: '#0d6efd', fontFamily: 'Montserrat',}} string={field.id} for={field.id}>
                        {field.name}{field.answer_required && <span style={{color: "red"}}>*</span>}
                    </label>
                    <Tooltip
                        placement="top"
                        overlay={( field.tooltip ? <span>{field.tooltip}</span> : <span>{field.name}</span>)}
                    >
                        <FaInfoCircle size={20} color='rgb(67, 133, 246)' style={{ position: 'relative', float: 'right', zIndex: 100000000, top: 40, right: 10 }} />
                    </Tooltip>
                    <br/>
                    <Input 
                    className='form-control' 
                    style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", padding: "10px 15px", width:"100%", height:"50px", minWidth: "200px", fontFamily: 'Montserrat', fontSize: 14}}
                    defaultValue={"None"}
                    name={field.id} 
                    required={field.answer_required}
                    id={field.id}
                    type="select"
                    onChange={(e) => {
                        updateValue({
                            answer_text: e.target.value,
                            choice: e.target.key,
                            question: field.id
                        })
                    }}>
                        <option value="None"></option>
                            {field.option_choices.map(op => <option key={op.pivot.option_choice_id} value={op.name} name={op.pivot.related_section}>{op.name}</option>)}
                    </Input>
                    <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                        Champ invalide!!!
                    </div>
                </FormGroup>
            </>
        )

    } else {
        if(field.input_type.element === 'textarea'){
            return (
                <>
                    <FormGroup id='input_div' style={{ textAlign: 'left', margin: "20px 0px", }}>
                        <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "14px", color: '#0d6efd', fontFamily: 'Montserrat',}} string={field.id} for={field.id}>
                            {field.name} {field.answer_required && <span style={{color: "red"}}>*</span>}
                        </label>
                        <Tooltip
                            placement="top"
                            overlay={( field.tooltip ? <span>{field.tooltip}</span> : <span>{field.name}</span>)}
                        >
                            <FaInfoCircle size={20} color='rgb(67, 133, 246)' style={{ position: 'relative', float: 'right', zIndex: 100000000, top: 40, right: 10 }} />
                        </Tooltip>
                        <br/>
                        <textarea 
                        className='form-control' 
                        style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", padding: "10px 15px", minWidth: "200px", fontFamily: 'Montserrat', fontSize: 14 }} 
                        name={field.id} 
                        required = {field.answer_required}
                        id={field.id}
                        data-choice={field?.option_choices[0]?.id}
                        rows={6} 
                        cols={60}
                        onChange={(e) => {
                            updateValue({
                                answer_text: e.target.value,
                                choice: field?.option_choices[0]?.id,
                                question: field.id
                            })
                        }}/>
                        <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                            Champ invalide!!!
                        </div>
                    </FormGroup>
                </>
            )
        } else if(field.input_type.type === 'radio'){
            return (
                <>
                    <FormGroup style={{ textAlign: 'left', margin: "20px 0px", }}>
                        <Label string={field.id} style={{ fontWeight: "normal", backgroundColor: "white", fontSize: "14px", color: '#0d6efd', fontFamily: 'Montserrat', }}>
                            {field.name}{field.answer_required && <span style={{color: "red"}}>*</span>}
                        </Label>
                        <Tooltip
                            placement="top"
                            overlay={( field.tooltip ? <span>{field.tooltip}</span> : <span>{field.name}</span>)}
                        >
                            <FaInfoCircle size={20} color='rgb(67, 133, 246)' style={{ position: 'relative', float: 'right', zIndex: 100000000, top: 0 }} />
                        </Tooltip>
                        {field.option_choices.map(op => 
                            <div style={{ color: "black", display: "flex" }}>
                                <Input 
                                 className="form-control"
                                 style={{ minWidth: "15px", width: "17px", height: "17px", marginRight: 10, fontFamily: 'Montserrat', fontSize: 14}}
                                 type="radio" 
                                 data-required={field.answer_required}
                                 defaultChecked={currentRadio === op.name}
                                 onChange={(e) => {
                                    const radios = document.querySelectorAll(`input[data-question="${field.id}"]`);
                                    radios.forEach(radio => {
                                        if (radio.value != op.name){
                                            radio.checked = false;
                                        }
                                    });
                                    setRadio(e.target.value)
                                    updateValue({
                                        answer_text: e.target.value,
                                        choice: e.target.id,
                                        question: field.id
                                    })
                                 }} 
                                 id={op.pivot.option_choice_id} 
                                 value={op.name}
                                 data-question={field.id}
                                 name={op.pivot.related_section}/>
                                <Label 
                                 string={op.name} 
                                 for={op.name} 
                                 style={{ fontWeight: "normal", fontSize: "14px", top: "-2px", right: "-5px", position: "relative", marginTop: 5, fontFamily: 'Montserrat', fontSize: 14}}>
                                    {op.name}
                                </Label>
                            </div>
                        )}
                    </FormGroup>
                </>
            )
        } else if(field.input_type.type === 'checkbox'){
            return (
                <>
                    <FormGroup style={{ textAlign: 'left', margin: "20px 0px", }}>
                        <Label string={field.id} style={{ fontWeight: "normal", backgroundColor: "white", fontSize: "14px", color: '#0d6efd', fontFamily: 'Montserrat', }}>
                            {field.name}{field.answer_required && <span style={{color: "red"}}>*</span>}
                        </Label>
                        <Tooltip
                            placement="top"
                            overlay={( field.tooltip ? <span>{field.tooltip}</span> : <span>{field.name}</span>)}
                        >
                            <FaInfoCircle size={20} color='rgb(67, 133, 246)' style={{ position: 'relative', float: 'right', zIndex: 100000000, top: 0 }} />
                        </Tooltip>
                        {field.option_choices.map(op => 
                            <div style={{ display: "flex"}}> 
                                <Input 
                                    className="form-control" 
                                    style={{ minWidth: '16px', height: '16px', marginRight: '10px', position: 'relative', fontFamily: 'Montserrat', fontSize: 14}} 
                                    type="checkbox"
                                    defaultChecked={currentCheckBoxes.includes(op.name)}
                                    data-question={field.id}
                                    data-required={field.answer_required}
                                    data-choice={op.pivot.option_choice_id}
                                    onChange={(e) => {
                                        if (e.target.checked){
                                            if (!currentCheckBoxes.includes(e.target.value)){
                                                setCheckboxes([ ...currentCheckBoxes, e.target.value ])
                                                updateValue({
                                                    answer_text: [ ...currentCheckBoxes, e.target.value ].join('; '),
                                                    choice: e.target.id,
                                                    question: field.id
                                                })
                                            }
                                        } else{
                                            setCheckboxes([...currentCheckBoxes.slice(0,currentCheckBoxes.indexOf(e.target.value)), ...currentCheckBoxes.slice(currentCheckBoxes.indexOf(e.target.value)+1)])
                                            updateValue({
                                                answer_text: [...currentCheckBoxes.slice(0,currentCheckBoxes.indexOf(e.target.value)), ...currentCheckBoxes.slice(currentCheckBoxes.indexOf(e.target.value)+1)].join('; '),
                                                choice: e.target.id,
                                                question: field.id
                                            })
                                        }
                                    }} 
                                    id={op.pivot.option_choice_id} 
                                    value={op.name}
                                    name={op.pivot.related_section}
                                />

                                <Label 
                                 string={op.name} 
                                 for={op.name} 
                                 style={{ fontWeight: "normal", fontSize: "14px", top: "-2px", right: "-5px", position: "relative", marginTop: 2, fontFamily: 'Montserrat',}}>
                                    {op.name}
                                </Label>
                            </div>
                        )}
                        
                    </FormGroup>
                </>
            )
        } else if(field.input_type.type === 'file'){
            return (
                <>
                    <FormGroup id='input_div' style={{ textAlign: 'left', margin: "20px 0px", }}>
                        <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "14px", color: '#0d6efd', fontFamily: 'Montserrat',}} string={field.id} for={field.id}>{field.name}{field.answer_required && <span style={{color: "red"}}>*</span>}</label>
                        <Tooltip
                            placement="top"
                            overlay={( field.tooltip ? <span>{field.tooltip}</span> : <span>{field.name}</span>)}
                        >
                            <FaInfoCircle size={20} color='rgb(67, 133, 246)' style={{ position: 'relative', float: 'right', zIndex: 100000000, top: 40, right: 10 }} />
                        </Tooltip>
                        <br/>
                        <input 
                        className='form-control' 
                        style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", width: "100%", height: "50px", padding: "0px 15px", fontFamily: 'Montserrat', minWidth: "200px", fontSize: 14 }} 
                        type={field.input_type.type} 
                        name={field.id} 
                        id={field.id}
                        multiple={true}
                        required = {field.answer_required}
                        accept="image/jpeg,image/gif,image/png,.pdf"
                        onChange={(e) => {
                            fileInputsData = [];
                            for (let index = 0; index < e.target.files.length; index++) {
                                const file = e.target.files[index];
                                readBlob(file, e);
                            }
                        }}/>
                        <div id={`invalid-feedback-${field.id}`} className="invalid-feedback" style={{ padding: "0px 15px", fontSize: 12 }}>
                            Champ invalide!!!
                        </div>
                    </FormGroup>
                </>
            )
        } else {
            return (
                <>
                    <FormGroup id='input_div' style={{ textAlign: 'left', margin: "20px 0px", }}>
                        <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "14px", color: '#0d6efd', fontFamily: 'Montserrat',}} string={field.id} for={field.id}>{field.name}{field.answer_required && <span style={{color: "red"}}>*</span>}</label>
                        <Tooltip
                            placement="top"
                            overlay={( field.tooltip ? <span>{field.tooltip}</span> : <span>{field.name}</span>)}
                        >
                            <FaInfoCircle size={20} color='rgb(67, 133, 246)' style={{ position: 'relative', float: 'right', zIndex: 100000000, top: 40, right: 10 }} />
                        </Tooltip>
                        <br/>
                        <input 
                        className='form-control' 
                        style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", width: "100%", height: "50px", padding: "0px 35px 0px 10px", fontFamily: 'Montserrat', minWidth: "200px", fontSize: 14}} 
                        type={field.input_type.type}
                        name={field.id}
                        pattern={field.pattern || undefined}
                        title={field.error_message || ''}
                        data-choice={field?.option_choices[0]?.id}
                        id={field.id}
                        required = {field.answer_required}
                        onChange={(e) => {
                            updateValue({
                                answer_text: e.target.value,
                                choice: field?.option_choices[0]?.id,
                                question: field.id
                            })
                        }}/>
                        <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                            Champ invalide !!!
                        </div>
                    </FormGroup>
                </>
            )
        }        
    }
}
