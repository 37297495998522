import React from 'react';
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { TiMessages } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import discuter from "../../assets/images/discuter.png";
import { convertDate } from '../../helpers/utils';
import "./ForumDiscussionRow.css";

const ForumDiscussionRow = (props) => {
    const { id, title, comments, date, decription } = props;
    const navigate = useNavigate()

    return (
        <div className="forum-discussion-row-container"
        onClick={() => navigate(`/forums-discussions/${id}`)}>
            <div className="row h-100">
                <div className="forum-discussion-row-image-container">
                    <img src={discuter} className="forum-discussion-row-image" alt={`pic of ${title}`} loading="lazy"/>
                </div>
                <div className="forum-discussion-row-details-container">
                    <p className="forum-discussion-row-details-title">{title?.length > 40 ? `${title.substring(
                            0,
                            35
                        )}...` : title}</p>
                    <div className="forum-discussion-row-details-info d-flex flex-wrap justify-content-evenly">
                            <span>

                                <BsFillCalendarCheckFill className="forum-discussion-row-details-info-icon" />
                                <span className="mx-2"> {convertDate(date?.substring(0, 10))}</span>
                            </span>
                            <span>
                                <TiMessages className="forum-discussion-row-details-info-icon" />
                                <span className="mx-2">{comments !== 0 ? (comments === 1 ? "1 commentaire" : `${comments} commentaires`) : "Aucun commentaire"}</span>
                            </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForumDiscussionRow
