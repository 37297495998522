import React, {useEffect, useState} from 'react';
import "./GlobalSearchItem.css";
import {BiCalendarCheck} from "react-icons/bi";
import {useParams} from "react-router-dom";
import { getATypeOfArticles, getArticleDetails} from '../../http/http';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


const GlobalSearchItem = () => {

    let { recherche_id } = useParams();
    const [resultData, setResultData] = useState();

    useEffect(() => {
        loadResultData();
    }, [recherche_id])

     const loadResultData = async () => {
        let searchedText = "";
        const resp = await getArticleDetails(recherche_id)
        if(resp.response && resp.response.status !== 200){
            console.log("data error ", resp.response)
        } else {
            setResultData(resp.data.data)
        }
    }

    return (  
        <div id="details-activities" className="details-activities m-auto">
             <Breadcrumb>
                <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
                <Breadcrumb.Item href="/recherche-globale">Recherche Globale</Breadcrumb.Item>
                <Breadcrumb.Item active>Détails</Breadcrumb.Item>
             </Breadcrumb>
        <br/>
        <br/>
        <br/>
        <br/>
       <div className="row d-flex">
        <div className="details-activities-cards-container">
           <div id="activity-card" className="activity-card" style={{paddingLeft: 20, paddingRight: 20}}>
           {resultData?.converted_image && <img src={resultData?.converted_image["1296_864"]} className="activity-card-img-searched" alt={resultData && resultData[0]?.title} loading="lazy"/>}
            <p className="activity-card-title pt-4">{resultData?.title}</p>
            <p className="activity-card-date"><BiCalendarCheck /> {resultData?.created_at.slice(8,10)} / {resultData?.created_at.slice(5,7)} / {resultData?.created_at.slice(0,4)} </p>
            <p className="details-activity-card-subtitle">
            <div className='ql-editor' dangerouslySetInnerHTML={{__html: (resultData?.content)}}></div>                   
            </p>
        </div>
        </div>
       </div>
    </div>
    )
}

export default GlobalSearchItem
