import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import HeroSection from '../../components/HeroSection/HeroSection';
import Loader from '../../components/Loader/Loader';
import { PaysMembresElementTemplate } from '../../components/PaysMembresElementTemplate/PaysMembresElementTemplate';
import { getATypeOfArticles } from '../../http/http';
import './PaysMembresListTemplate.css';

export const PaysMembresListTemplate = () => {


  const { menu } = useParams();
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadArticlesData = async () => {
      const resp = await getATypeOfArticles(menu, 1)
      if (resp.response && resp.response.status !== 200) {
        console.log("data data error ", resp.response)
      } else {
        setArticles(resp.data)
        setIsLoading(false)
      }
    }

    loadArticlesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return (
    <div className='pb-5' >
      {!isLoading ?
        <>
          <HeroSection />
          <Breadcrumb>
            <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
            {localStorage.getItem('secondMenu-active') !== "undefined" && <Breadcrumb.Item href="#">{(localStorage.getItem('secondMenu-active'))}</Breadcrumb.Item>}
            <Breadcrumb.Item active>{JSON.parse(localStorage.getItem('active-menu'))?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            {articles.map(article =>
              <>
                <h3 className='section-title'>{article?.name}</h3>
                {(article.sections.length > 0) ?
                  <>
                    <div className='mx-auto'>
                      {article?.sections.map(section => {
                        return <div key={section.name}>
                          <div className='continent-title'>{section.name}</div>
                          <div className='mx-auto pays-container d-flex flex-wrap justify-evenly'>
                            {section.items.map(item =>
                              <PaysMembresElementTemplate key={item.image_url}
                                title={item.title}
                                image_url={item.image_url}
                                link={item.sub_title} />)}
                          </div>
                        </div>
                      })}
                    </div>
                  </>
                  :
                  <>
                    <div className='mx-auto pays-container d-flex flex-wrap justify-evenly'>
                      {article?.items.map(item =>
                        <PaysMembresElementTemplate key={item.image_url}
                          title={item.title}
                          image_url={item.image_url}
                          link={item.sub_title} />)}
                    </div>
                  </>
                }
              </>)}

          </div>
        </>
        :
        <Loader />}


    </div>
  )
}
