import React, { useState } from 'react'
import './HeroSection.css'

const HeroSection = () => {
    const [active, setActive] = useState("")
  return (
    <div>
        <div className="big__section"></div>
    </div>
  )
}

export default HeroSection