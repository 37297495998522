import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, NavDropdown } from "react-bootstrap";
import { BiSearchAlt } from 'react-icons/bi';
import { FaBars } from "react-icons/fa";
import {
    useNavigate
} from "react-router-dom";
import {
    ButtonGroup,
    Collapse,
    Nav,
    Navbar,
    NavbarToggler
} from 'reactstrap';

const NavBar = (props) => {

    const { secondMenus } = props;
    const navigate = useNavigate()
    const [state, setState] = useState(
        {
            isOpen: false,
            showDropdownActualites: false,
            showDropdownRegime: false,
            showDropdownDemarches: false,
            showDropdownDocumentation: false,
            showDropdownConformite: false,
            showDropdownTextesNationaux: false,
            showSub: "",
            showSubSub: ""
        }
    )

    const [activeMenu, setActiveMenu] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        let temp = secondMenus?.filter(item => item.id == activeMenu)
        localStorage.setItem("secondMenu-active", (temp[0]?.name))
    }, [activeMenu])


    const searchDecisionsShortcut = () => {
        window.location.href = "/recherche-globale"
    }

    return (
        <React.Fragment>
            <Navbar light expand="sm" style={{ backgroundColor: "rgb(18 50 95)", marginBottom: 0, borderRadius: 0, }} className={"d-flex justify-content-between nav-bar-mother"}  >
                <NavbarToggler onClick={() => setIsOpen(!isOpen)}> <FaBars color='white' size={20} style={{ border: 'none' }} /> </NavbarToggler>
                <BiSearchAlt title='Rechercher globalement sur le site' onClick={searchDecisionsShortcut} size={25} style={{ color: 'white', cursor: 'pointer', position: 'relative' }} />
                <Collapse isOpen={isOpen} navbar className="nav-bar-custom w-100">
                    <Nav className="mr-autor  d-flex flex-wrap justify-content-around" navbar>
                        <h3 className={window.location.pathname === "/" ? "nav-link-title nav-item-custom home-menu home-menu_active " : "nav-link-title nav-item-custom home-menu"}
                            active={
                                window.location.pathname === "/"
                            }
                            onClick={() => {
                                setActiveMenu('home')
                            navigate(`/`)
                        }}>Accueil</h3>
                        {secondMenus.length && secondMenus?.map((secondMenu, index) => 
                        <h3 key={secondMenu.name}>
                            <NavDropdown
                                onMouseLeave={() => setState({ ...state, showSub: "" })}
                                onMouseOver={() => setState({ ...state, showSub: secondMenu.name })}
                                show={state.showSub == secondMenu?.name}
                                className={"nav-link-title nav-item-custom"} title={secondMenu.name} id="navbarScrollingDropdown"
                                active={
                                    secondMenu?.id == activeMenu
                                }>
                                {secondMenu.children?.sort((a, b) => (a.order > b.order) ? 1 : -1).map((subMenu, index) =>
                                    <>

                                        {subMenu.children?.length === 0 ? <NavDropdown.Item key={index + "p"} onClick={() => {
                                            setActiveMenu(subMenu.parent_id)
                                            if (subMenu?.model == "redirection_url") {
                                                window.open(`/${subMenu?.model}/${subMenu?.slug}`, '_blank');
                                            } else {
                                                localStorage.setItem('active-menu', JSON.stringify(subMenu))
                                                navigate(`/${subMenu?.model}/${subMenu?.slug}`)
                                            }
                                        }
                                        }
                                        >{subMenu.name} </NavDropdown.Item> :
                                            <div>
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    key={'end'}
                                                    id={`dropdown-button-drop-end`}
                                                    drop={'end'}
                                                    variant="none"
                                                    size="lg"
                                                    title={subMenu.name}
                                                    onMouseLeave={() => { localStorage.setItem('showSubSub', "") }}
                                                    onMouseOver={() => { localStorage.setItem('showSubSub', subMenu.name) }}
                                                    show={localStorage.getItem('showSubSub') == subMenu.name}
                                                >
                                                    {subMenu.children.sort((a, b) => (a.order > b.order) ? 1 : -1).map((subSubMenu, index) => <Dropdown.Item
                                                        key={index}    
                                                        onClick={() => {
                                                            setActiveMenu(subMenu.parent_id)
                                                            localStorage.setItem('active-menu', JSON.stringify(subSubMenu))
                                                            navigate(`/${subSubMenu?.model}/${subSubMenu?.slug}`)
                                                        }} eventKey="1">{subSubMenu.name}</Dropdown.Item>)}
                                                </DropdownButton>
                                            </div>
                                        }

                                    </>
                                )}
                            </NavDropdown>
                        </h3>)}

                    </Nav >
                </Collapse >
            </Navbar >

        </React.Fragment >
    )

}

export default NavBar
