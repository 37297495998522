import React from 'react'
import "./PaysMembresElementTemplate.css"

export const PaysMembresElementTemplate = ({title, image_url, link}) => {


    const redirectTo =  (param) => {
            window.location.href = param;  
    }             

  return (
    <div className='pays-membre-element-card' onClick={() => redirectTo(link)}>
        <img src={image_url} alt={title} loading="lazy"/>
        <br />
        <p>{title}</p>
    </div>
  )
}
